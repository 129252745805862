<template>
  <b-card
    class="card-app-design rooms-container"
    title="Register Income"
  >
    <b-row>
      <b-col sm="12">
        <div class="custom-search d-md-flex justify-content-between mb-3">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
          <b-button
            variant="outline-primary"
            size="md"
            @click="handleOpenRegisterModal()"
          >
            Register
          </b-button>
        </div>

        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :pagination-options="{
            enabled: false,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
            >
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span class="d-flex justify-content-center">
                <b-button
                  variant="outline-primary"
                  @click="handleOpenViewIncomeModal(props.row)"
                >
                  <feather-icon
                    icon="EyeIcon"
                  />
                </b-button>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">

              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        <b-modal
          v-model="openRegisterLivestockDetailsModal"
          title="Livestock Details"
          size="lg"
          ok-only
        >
          <b-row>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Income Type:</span><span class="text-info ml-1">{{ incomeFormData.income_name }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Amount:</span><span class="text-info ml-1">{{ formatNumber(incomeFormData.amount) }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Transaction Date:</span><span class="text-info ml-1">{{ incomeFormData.transaction_date }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Recieved By:</span><span class="text-info ml-1">{{ incomeFormData.recieved_by }}</span></p>
            </b-col>
            <b-col
              sm="12"
            >
              <p><span>Remarks:</span><span class="text-info ml-1">{{ incomeFormData.remarks }}</span></p>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          v-model="openRegisterIncomeModal"
          title="Register Farm Income"
          size="lg"
          ok-title="Submit"
          @ok.prevent="validationForm"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group label="Select Income Type">
                    <validation-provider
                      #default="{ errors }"
                      name="Income Type"
                      rules="required"
                    >
                      <b-form-select
                        v-model="incomeTypeItem"
                        :options="incomeTypeItemOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group label="Total Price">
                    <validation-provider
                      #default="{ errors }"
                      name="Total Price"
                      rules="required"
                    >
                      <b-form-input
                        v-model="incomeFormData.amount"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Total Price(UGX)"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group label="Transaction Date">
                    <validation-provider
                      #default="{ errors }"
                      name="Transaction Date"
                      rules="required"
                    >
                      <b-form-datepicker
                        v-model="incomeFormData.transaction_date"
                        placeholder="Choose Transaction Date"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group label="Recieved By">
                    <validation-provider
                      #default="{ errors }"
                      name="Recieved By"
                      rules="required"
                    >
                      <b-form-select
                        v-model="approvedBy"
                        :options="approvedByOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="12"
                >
                  <b-form-group label="Remarks">
                    <b-form-textarea
                      v-model="incomeFormData.remarks"
                      placeholder="Enter Additional Notes"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BBadge,
  BFormDatepicker, BFormSelect, BFormTextarea, BModal,
  BForm, BPagination, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store/index'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { supabase } from '@/libs/supabaseClient'
// eslint-disable-next-line import/no-cycle
import { formatNumber } from '@core/utils/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BModal,
    BForm,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
  },
  data() {
    return {
      log: [],
      pageLength: 3,
      openRegisterIncomeModal: false,
      openRegisterLivestockDetailsModal: false,
      openManageLivestockModal: false,
      selectedStatus: null,
      incomeTypeItem: null,
      approvedBy: null,
      itemUnit: null,
      recievedBy: null,
      configIncomeData: [],
      dir: false,
      required,
      formatNumber,
      email,
      userProfileData: [],
      incomeFormData: {
        income_name: '',
        income_id: 0,
        amount: 0,
        transaction_date: '',
        transaction_date_time: 0,
        recieved_by: '',
        recieved_by_id: 0,
        remarks: '',
      },
      pages: ['3', '5', '10'],
      approvedByOptions: [
        { value: null, text: 'Select Staff', disabled: true },
      ],
      recievedByOptions: [
        { value: null, text: 'Select staff', disabled: true },
        { value: 'John Doe', text: 'John Doe' },
      ],
      itemUnitOptions: [
        { value: null, text: 'Select Unit', disabled: true },
        { value: 'Pieces', text: 'Pieces' },
        { value: 'Kgs', text: 'Kgs' },
        { value: 'Ltrs', text: 'Ltrs' },
      ],
      incomeTypeItemOptions: [
        { value: null, text: 'Select Income', disabled: true },
      ],
      livestockStatusOptions: [
        { value: null, text: 'Select Status', disabled: true },
        { value: 'Died', text: 'Died' },
        { value: 'Slaughtered for domestic consumption', text: 'Slaughtered for domestic consumption' },
        { value: 'Donated', text: 'Donated' },
      ],
      columns: [
        {
          label: 'Date',
          field: 'transaction_date',
        },
        {
          label: 'Name',
          field: 'income_name',
        },
        {
          label: 'Amount',
          field(item) {
            return formatNumber(item.amount)
          },
        },
        {
          label: 'Recieved By',
          field: 'recieved_by',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.handleGetConfigIncome()
    this.handleGetIncomeData()
  },
  methods: {
    resetIncomeFormData() {
      this.incomeTypeItem = null
      this.approvedBy = null
      this.incomeFormData = {
        income_name: '',
        income_id: 0,
        amount: 0,
        transaction_date: '',
        transaction_date_time: 0,
        recieved_by: '',
        recieved_by_id: 0,
        remarks: '',
      }
    },
    handleOpenRegisterModal() {
      this.openRegisterIncomeModal = true
    },
    handleOpenViewIncomeModal(item) {
      this.openRegisterLivestockDetailsModal = true
      this.incomeFormData = item
    },
    handleOpenEditIncomeModal(item) {
      this.openRegisterIncomeModal = true
      this.incomeFormData = item
    },
    async handleGetConfigIncome() {
      this.$Progress.start()
      try {
        const { data: response, error } = await supabase
          .from('config_income_type')
          .select(`
            *
          `).order('name', { ascending: true })
        const { data: userResponse, userError } = await supabase
          .from('profiles')
          .select(`
            *
          `).order('first_name', { ascending: true })
        if (error || userError) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Fetch Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else {
          if (response.length > 0) {
            this.configIncomeData = response
            response.map(item => this.incomeTypeItemOptions.push(
              {
                value: item.id,
                text: item.name,
              },
            ))
          }

          if (userResponse.length > 0) {
            this.userProfileData = userResponse
            userResponse.map(item => this.approvedByOptions.push(
              {
                value: item.id,
                text: `${item.first_name} ${item.last_name}`,
              },
            ))
          }
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Fetch Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.$Progress.finish()
      }
    },
    async handleGetIncomeData() {
      this.$Progress.start()
      try {
        const { data: response, error } = await supabase
          .from('income')
          .select(`
            *
          `).order('transaction_date_time', { ascending: false })
        if (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Fetch Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else if (response.length > 0) {
          this.rows = []
          this.rows = response
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Fetch Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.$Progress.finish()
      }
    },
    handleGetIncomeTypeData() {
      const selectedItem = this.configIncomeData.filter(item => item.id === this.incomeTypeItem)
      return selectedItem
    },
    handleGetUserData() {
      const selectedItem = this.userProfileData.filter(item => item.id === this.approvedBy)
      return selectedItem
    },
    async handleRegisterIncome() {
      this.$Progress.start()
      const selectedIncomeData = this.handleGetIncomeTypeData()
      const selectedUser = this.handleGetUserData()
      this.incomeFormData.income_id = selectedIncomeData[0].id
      this.incomeFormData.income_name = selectedIncomeData[0].name
      this.incomeFormData.recieved_by_id = selectedUser[0].id
      this.incomeFormData.recieved_by = `${selectedUser[0].first_name} ${selectedUser[0].last_name}`
      // eslint-disable-next-line radix
      this.incomeFormData.amount = parseInt(this.incomeFormData.amount)
      const transactionDateTimesatmp = new Date(this.incomeFormData.transaction_date).getTime()
      this.incomeFormData.transaction_date_time = transactionDateTimesatmp / 1000
      try {
        const { error } = await supabase
          .from('income')
          .insert([
            this.incomeFormData,
          ])
          .select()
        if (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Registration Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Registration Successful',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Data posted successfully',
            },
          })
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Registration Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.openRegisterIncomeModal = false
        this.resetIncomeFormData()
        this.handleGetIncomeData()
        this.$Progress.finish()
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.handleRegisterIncome()
        }
      })
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
